import React, { useEffect, useState } from "react";
import "../assets/css/aquarium.css";
const FichePoisson = ({ data, onClose }) => {
	const handleClose = () => {
		// Appeler la fonction de fermeture
		onClose();
	};

	useEffect(() => {
		console.log("FP data", data);
	}, []);

	return (
		<div className="back-layer">
			<div className="fiche-produit">
				<button className="close" onClick={handleClose}></button>
				<div className="divider">
					<div className="image" style={{ backgroundImage: `url(${process.env.REACT_APP_API_URL}${"/uploads/fishes/" + data.image1})` }}></div>
					<div className="wrapper">
						<div className="informations">
							<div className="famille">{data.famille}</div>
							<div className="wrapper-1">
								<div className="prix">{data.prix}€</div>
								<div className="taille">Taille :&nbsp; {data.taille}cm</div>
								<div className="age">Age : {data.age} ans</div>
							</div>

							<div className="reference">Référence de la carpe :&nbsp;{data.reference}</div>
							<div className="eleveur">Eleveur :&nbsp; {data.eleveur}</div>
							<div className="couleurs">Couleurs :&nbsp; {data.couleurs}</div>

							<div className="type">Type : &nbsp; {data.type}</div>
							<div className="isReserved">Status :&nbsp; {data.reserve_by ? "carpe réservée" : "carpe libre !"}</div>
							<div className="description">
								<b>Description</b> :&nbsp; {data.description}{" "}
							</div>

							<div className="wrapper-2">
								<span>Cette carpe est libre ! la réserver ?</span>
								<button>Ajouter au panier</button>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default FichePoisson;
