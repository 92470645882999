import axios from "axios";
import { JWTFromLocalStorage } from "../services/jwt";

const ax = axios.create({
	baseURL: process.env.REACT_APP_API_URL,
});

ax.interceptors.request.use(
	(config) => {
		const token = JWTFromLocalStorage();

		if (token) {
			config.headers.Authorization = `Bearer ${token}`;
		}
		return config;
	},
	(error) => {
		return Promise.reject(error);
	}
);

export default ax;
