import React, { useState, useEffect } from "react";

import ActivationBox from "../composants/ActivationBox";

import ax from "../services/axiosInterceptor";
import { isLogged } from "../services/jwt";

import "../assets/css/messages.css";
import "../assets/css/forms.css";

const Contact = () => {
	const [loggedIn, setLoggedIn] = useState(false);
	const [clientEmail, setClientEmail] = useState("");
	const [sujet, setSujet] = useState("");
	const [message, setMessage] = useState("");
	const [showActivationBox, setShowActivationBox] = useState(false);

	useEffect(() => {
		const checkLoginStatus = async () => {
			const loggedInStatus = await isLogged();
			setLoggedIn(loggedInStatus);
		};

		checkLoginStatus();
	}, []);

	const handleSubmit = async (event) => {
		event.preventDefault();
		if (!validateClientEmail(clientEmail)) {
			console.log("email incorrect");
		} else {
			console.log("demande de mail...");
			try {
				const response = await ax.post("/mail", { sujet: sujet, message: message, clientEmail: clientEmail });
				console.log("retour mail :", response);
				if (response.data.success) {
					setShowActivationBox(true);
					console.log("Mail envoyé");
				}
			} catch (error) {
				console.error("Erreur lors de la soumission du formulaire", error);
			}
		}
	};

	const validateClientEmail = (clientEmail) => {
		const regex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
		return regex.test(clientEmail);
	};

	const handleChangeClientEmail = (event) => {
		setClientEmail(event.target.value);
	};

	const handleChangeSujet = (event) => {
		setSujet(event.target.value);
	};

	const handleChangeMessage = (event) => {
		setMessage(event.target.value);
	};

	const handleCloseActivationBox = () => {
		setShowActivationBox(false);
	};

	return (
		<div className="form-container contact">
			<h2>Contactez-nous</h2>
			{loggedIn ? (
				<form className="contact" onSubmit={handleSubmit}>
					<input type="text" name="email" placeholder="Entrez votre email" value={clientEmail} onChange={handleChangeClientEmail} required />
					<input type="text" name="sujet" placeholder="Sujet de votre mail" value={sujet} onChange={handleChangeSujet} required />
					<textarea name="message" value={message} onChange={handleChangeMessage} placeholder="Veuillez entrer votre message" required />

					<button type="submit" name="submit" className="primary">
						Envoyer le message
					</button>
				</form>
			) : (
				<p>Merci de vous connecter pour nous envoyer un email, ou de créer un compte si vous n'en n'avez pas.</p>
			)}
			{showActivationBox && <ActivationBox messageCode={2} close={handleCloseActivationBox} redirection={false} />}
		</div>
	);
};

export default Contact;
