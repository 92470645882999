const JWTtoLocalStorage = (jwt) => {
	const localStorage = window.localStorage;
	localStorage.setItem("jwtToken", jwt);
};

const JWTFromLocalStorage = () => {
	const storedJwtToken = localStorage.getItem("jwtToken");
	return storedJwtToken;
};

const removeAllFromLocalStorage = () => {
	localStorage.removeItem("jwtToken");
	localStorage.removeItem("authData");
};

const AuthDataFromLocalStorage = () => {
	const storedAuthData = localStorage.getItem("authData");
	return JSON.parse(storedAuthData);
};

const AuthDataToLocalStorage = (authdata) => {
	const localStorage = window.localStorage;
	localStorage.setItem("authData", JSON.stringify(authdata));
};

/*const encodeJWT = () => {
	const storedJwtToken = JSON.parse(localStorage.getItem("jwtToken"));
	if (storedJwtToken) {
		const decoded = jwtDecode(storedJwtToken);
		process.env.JWT_SECRET;
	}
};*/

const checkJwtSecure = () => {
	//const ax.get("/jwt-secure")
};

const isLogged = async () => {
	//Si j'ai un JWT, j'ai le status CONNECTé.
	const jwt = JWTFromLocalStorage();
	if (jwt) {
		return true;
	} else {
		return false;
	}
};

export { JWTtoLocalStorage, JWTFromLocalStorage, removeAllFromLocalStorage, AuthDataFromLocalStorage, AuthDataToLocalStorage, isLogged, checkJwtSecure };
