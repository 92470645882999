import React, { useState } from "react";
import { useNavigate, Link } from "react-router-dom";

import ax from "../services/axiosInterceptor";

import "../assets/css/messages.css";

const MessageBox = ({ message, buttonText, redirection, close }) => {
	const navigate = useNavigate();

	const handleClose = () => {
		if (redirection) {
			navigate(redirection);
		}

		if (close) {
			close(); // Appel de la méthode close passée en prop
		}
	};

	return (
		<div className="backlayer">
			<form className="message">
				<div className="cross" onClick={handleClose}>
					✖️
				</div>
				{message === "thanks" && (
					<>
						<div className="message thanks">
							<img alt="image de remerciement" src="https://png.pngtree.com/png-clipart/20230801/original/pngtree-thank-you-handwritten-inscription-picture-image_7805401.png" />
							<p>Merci de vous être inscript sur PK28.com ! </p>
							<p>Vous allez recevoir un code dans votre boite mail. </p>
							<p>Il faudra vous connecter une 1ère fois et entrer le code pour valider votre compte. </p>
							<p>Ensuite, logguez-vous et c'est parti !</p>
							<p>Merci !</p>
						</div>
						<Link to="/homepage">
							<button className="primary">Merci</button>
						</Link>
					</>
				)}
			</form>
		</div>
	);
};

export default MessageBox;
