// Footer.jsx

import React from "react";
import "../assets/css/footer.css";

const Footer = () => {
	return (
		<footer>
			<ul className="footer">
				<li>
					<div className="title">Contactez-nous</div>
					<div>23 rue des Beaucerons</div>
					<div>28044 Authon-du-perche</div>
					<div>Mini-carte</div>
				</li>
				<li>
					<div className="title">Boutiques en ligne</div>
					<div>Boutique Passionkoi28.com</div>
					<div>Facebook</div>
					<div>Instagram</div>
				</li>
				<li>
					<div className="title">Conditions générales</div>
					<div>RGPD</div>
					<div>Légal</div>
				</li>
				<li>
					<div className="title">Abonnements</div>
					<div>Newsletter</div>
					<div>Nourriture</div>
					<div>Fournitures</div>
				</li>
			</ul>
		</footer>
	);
};

export default Footer;
