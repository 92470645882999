import React, { useContext, useState } from "react";
import { Link } from "react-router-dom";

import SidebarMenuMobile from "./SidebarMenuMobile";

import { AuthContext } from "../services/AuthContext";
import "../assets/css/navigation.mobile.css";

function NavigationMobile() {
	const [isMenuOpen, setIsMenuOpen] = useState(false);
	const { authData, setAuthData } = useContext(AuthContext);

	const toggleMenu = () => {
		setIsMenuOpen(!isMenuOpen);
		console.log("Clicked Burger !");
	};

	return (
		<>
			<nav className="navigation-mobile">
				<div className="loupe"></div>
				<div className="logo"></div>
				<div className="burger" onClick={toggleMenu}></div>
			</nav>
			<div className={isMenuOpen ? "sidebar-menu-mobile" : "sidebar-menu-mobile sidebar-menu-hidden"}>
				<ul>
					<li>
						<Link to="/login" onClick={toggleMenu}>
							Login
						</Link>
					</li>
					<li>
						<Link to="/" onClick={toggleMenu}>
							Accueil
						</Link>
					</li>
					<li>
						<Link to="/aquarium" onClick={toggleMenu}>
							Aquarium
						</Link>
					</li>
					<li>
						<Link to="/boutique" onClick={toggleMenu}>
							Boutique
						</Link>
					</li>
					{authData.email && (
						<li>
							<Link to="/panier" onClick={toggleMenu}>
								Panier
							</Link>
						</li>
					)}
					{authData.email && (
						<li>
							<Link to="/update-account" onClick={toggleMenu}>
								Mon compte
							</Link>
						</li>
					)}
					<li>
						<Link to="/contact" onClick={toggleMenu}>
							Contacter
						</Link>
					</li>
				</ul>
			</div>
		</>
	);
}

export default NavigationMobile;
