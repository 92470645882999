import React, { useEffect } from "react";
import "../assets/css/panier.css";

import Vignette from "../composants/Vignette";

const Panier = () => {
	useEffect(() => {}, []);

	return (
		<div className="panier-container">
			<h2>Votre Panier</h2>
			<div className="wrapper">
				<Vignette data={{}} showTrash="true" />
				<Vignette data={{}} showTrash="true" />
				<Vignette data={{}} showTrash="true" />
			</div>
		</div>
	);
};

export default Panier;
