import React, { useState, useContext, useEffect } from "react";
import axios from "axios";

import ax from "../services/axiosInterceptor";
import { AuthContext } from "../services/AuthContext";

import "../assets/css/sidebar.desktop.css";

const SidebarFilter = () => {
	/* USESTATES et USECONTEXT */

	let minDistance = 10;

	const { authData, setAuthData } = useContext(AuthContext);
	const [selectedColors, setSelectedColors] = useState([]);
	const [selectedFamille, setSelectedFamille] = useState([]);

	const [familles, setFamilles] = useState([]);
	const [value1, setValue1] = useState([20, 37]);

	/* SLIDER PRIX */
	const handleChange1 = (event, newValue, activeThumb) => {
		if (!Array.isArray(newValue)) {
			return;
		}

		if (activeThumb === 0) {
			setValue1([Math.min(newValue[0], value1[1] - minDistance), value1[1]]);
		} else {
			setValue1([value1[0], Math.max(newValue[1], value1[0] + minDistance)]);
		}
	};

	/* Changement de critère COULEUR */
	const handleChangeCouleur = (e) => {
		const couleur = e.currentTarget.getAttribute("couleur");
		console.log("couleurs du bouton clické", couleur);
		// Utilisez setSelectColors pour mettre à jour le tableau

		setSelectedColors((prevColors) => {
			console.log("prevColors", prevColors);
			// Si la couleur est déjà présente, la retirer
			if (prevColors.includes(couleur)) {
				return prevColors.filter((color) => color !== couleur);
			} else {
				// Sinon, l'ajouter
				return [...prevColors, couleur];
			}
		});
	};

	/* Changement de critère FAMILLE */
	{
		/*const handleChangeType = (e) => {
		const type = e.currentTarget.getAttribute("type");

		// Utilisez setSelectColors pour mettre à jour le tableau
		setSelectedTypes((prevTypes) => {
			// Si la couleur est déjà présente, la retirer
			if (prevTypes.includes(type)) {
				return prevTypes.filter((typ) => typ !== type);
			} else {
				// Sinon, l'ajouter
				return [...prevTypes, types];
			}
		});
	};*/
	}

	const handleChangeFamille = (event) => {
		setSelectedFamille(event.target.value);
	};

	/* MISE A JOURS DES DONNNEES DU FILTRE */

	useEffect(() => {
		const updatedAuthData = { ...authData };

		// Add or update the "couleurs" property under "filtre"
		updatedAuthData.filtre = {
			...updatedAuthData.filtre,
			couleurs: selectedColors,
		};

		updatedAuthData.filtre = {
			...updatedAuthData.filtre,
			familles: selectedFamille,
		};

		// Update authData with the modified object
		setAuthData(updatedAuthData);
	}, [selectedColors, selectedFamille]); // <-- Add selectedColors as a dependency

	useEffect(() => {
		const fetchData = async () => {
			try {
				const response = await ax.get("/fishes/families");
				console.log("SidebarFilter.jsx > au montage", response.data);
				setFamilles(response.data);
			} catch (error) {
				console.error("Erreur lors du chargement des données de la boutique", error);
			}
		};
		fetchData();
	}, []);

	return (
		<div className="sidebar-filter sidebarfilter-animation ">
			<div className="spinners">
				<span>Effacer</span>
			</div>
			<div className="critere familles">
				<h2>Famille</h2>
				<select style={{ padding: "0.5rem 0.75rem", fontFamily: "" }}>
					{familles.map((fam, key) => {
						return (
							<option key={key} value={fam.id}>
								{fam.name}
							</option>
						);
					})}
				</select>
				{/*<div className="wrapper">
					{familles.map((fam, key) => {
						return (
							<button key={key} className={`famille ${fam.name} ${selectedFamille.includes(fam.name) ? "active" : ""}`} famille={fam.name} onClick={handleChangeFamille}>
								{fam.name}
							</button>
						);
					})}
				</div>*/}
			</div>
			<div className="critere couleurs">
				<h2>Couleurs</h2>
				<div className="wrapper">
					<button className={`couleur noir ${selectedColors.includes("noir") ? "active" : ""}`} couleur="noir" onClick={handleChangeCouleur}>
						Noir
					</button>
					<button className={`couleur blanc ${selectedColors.includes("blanc") ? "active" : ""}`} couleur="blanc" onClick={handleChangeCouleur}>
						Blanc
					</button>
					<button className={`couleur rouge ${selectedColors.includes("rouge") ? "active" : ""}`} couleur="rouge" onClick={handleChangeCouleur}>
						Rouge
					</button>
					<button className={`couleur orange ${selectedColors.includes("orange") ? "active" : ""}`} couleur="orange" onClick={handleChangeCouleur}>
						Orange
					</button>
					<button className={`couleur jaune ${selectedColors.includes("jaune") ? "active" : ""}`} couleur="jaune" onClick={handleChangeCouleur}>
						Jaune
					</button>
				</div>
			</div>
			{/*<div className="critere types">
				<h2>Types</h2>
				<div className="wrapper">
					{types.map((typ, key) => {
						return (
							<button key={key} className={`type ${typ} ${selectedTypes.includes(typ) ? "active" : ""}`} famille={typ} onClick={handleChangeType}>
								{typ}
							</button>
						);
					})}
				</div>
			</div>*/}
			{/*
			<div className="critere prix">
				<h2>Prix</h2>
				<Slider getAriaLabel={() => "Minimum distance"} value={value1} onChange={handleChange1} valueLabelDisplay="auto" getAriaValueText={valuetext} disableSwap />
				<input className="base-input prix" name="prix" type="text" placeholder="€" />
				<input className="base-input prix" name="prix" type="text" placeholder="€" />
			</div>*/}
			<div className="critere age">
				<h2>Age</h2>
				<div>
					<input type="checkbox" id="age-1an" name="age-1an" />
					<label htmlFor="age-1an">1 an</label>
				</div>
				<div>
					<input type="checkbox" id="age-2ans" name="age-2ans" />
					<label htmlFor="age-2ans">2 ans</label>
				</div>
				<div>
					<input type="checkbox" id="age-3ans" name="age-3ans" />
					<label htmlFor="age-3ans">3 ans</label>
				</div>
				<div>
					<input type="checkbox" id="age-plus3ans" name="plus3ans" />
					<label htmlFor="age-plus3ans">+ 3ans</label>
				</div>
			</div>

			<div className="critere concours">
				<h2>Concours</h2>
				<div>
					<input type="checkbox" id="concours-1erprix" name="concours-1erprix" />
					<label htmlFor="concours-1erprix">1er prix</label>
				</div>
				<div>
					<input type="checkbox" id="concours-2eprix" name="concours-2eprix" />
					<label htmlFor="concours-2eprix">2e prix</label>
				</div>
				<div>
					<input type="checkbox" id="concours-3eprix" name="concours-3eprix" />
					<label htmlFor="concours-3eprix">3e prix</label>
				</div>
			</div>

			<div className="critere eleveur">
				<h2>Eleveur</h2>
				<div>
					<input type="checkbox" id="eleveur-yamasan" name="eleveur-yamasan" />
					<label htmlFor="eleveur-yamasan">Yamasan</label>
				</div>
				<div>
					<input type="checkbox" id="eleveur-yamanaka" name="eleveru-yamanaka" />
					<label htmlFor="eleveur-yamanaka">Yamanaka</label>
				</div>
			</div>
		</div>
	);
};

export default SidebarFilter;
