import React, { useState, useEffect, useContext } from "react";
import { isMobile } from "react-device-detect";

// Importez Axios
import SidebarFilter from "../composants/SidebarFilter";
import Vignette from "../composants/Vignette";

import { AuthContext } from "../services/AuthContext";
import ax from "../services/axiosInterceptor";

import "../assets/css/aquarium.css";

const Aquarium = () => {
	const [fishes, setFishes] = useState([]);
	const [filteredFishes, setFilteredFishes] = useState(fishes);
	const { authData, setAuthData } = useContext(AuthContext);

	{
		/* FILTRAGE DES PRODUITS Fishes)*/
	}
	const filteringFishes = () => {
		if (authData["filtre"] !== undefined) {
			if (authData.filtre["couleurs"] !== undefined) {
				const toFilterColors = authData.filtre["couleurs"] || [];
				const toFilterFamilies = authData.filtre["familles"] || [];

				// Filtrer par couleurs
				const filteredColorsFishes =
					toFilterColors.length > 0
						? fishes.filter((el) => {
								console.log("el : ", el);
								const splittedCouleurs = el.couleurs.split(",");
								if (splittedCouleurs.length === 1) {
									return toFilterColors.includes(splittedCouleurs[0].toLowerCase());
								}
								if (splittedCouleurs.length === 2) {
									return toFilterColors.includes(splittedCouleurs[0].toLowerCase()) && toFilterColors.includes(splittedCouleurs[1].toLowerCase());
								}
						  })
						: fishes;

				// Filtrer par familles
				//const filteredFamiliesProducts = toFilterFamilies.length > 0 ? filteredColorsProducts.filter((el) => toFilterFamilies.includes(el.famille.toLowerCase())) : filteredColorsProducts;

				setFilteredFishes(filteredColorsFishes);
			}
		} else {
			// Si aucune couleur ni famille sélectionnée, afficher tous les produits
			setFilteredFishes(fishes);
		}
	};

	// Si on clique sur un element du filtre
	useEffect(() => {
		filteringFishes();
	}, [authData]);

	useEffect(() => {
		const fetchData = async () => {
			try {
				const response = await ax.get("/fishes");
				setFishes(response.data);
				setFilteredFishes(response.data);
			} catch (error) {
				console.error("Erreur lors du chargement des données de la boutique", error);
			}
		};
		fetchData();
		filteringFishes();
	}, []);

	return (
		<div className="boutique-container">
			{!isMobile && <SidebarFilter />}
			<div className="main-products bg-ecailles-light">
				<div className={"products-container " + (isMobile ? "mobile" : "")}>
					{/* Parcourez les produits et affichez-les sous forme de liste */}
					{filteredFishes ? filteredFishes.map((product, key) => <Vignette key={key} data={product} showTrash={false} productType="fish" />) : null}
				</div>
			</div>
		</div>
	);
};

export default Aquarium;
