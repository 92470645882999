import React, { useState, useEffect, useContext } from "react";

import { AuthContext } from "../services/AuthContext";
import ax from "../services/axiosInterceptor";
import "../assets/css/messages.css";

const UpdateAccount = () => {
	const { authData, setAuthData } = useContext(AuthContext);
	const [error, setError] = useState(false);
	const [message, setMessage] = useState(false);
	const [accountData, setAccountData] = useState({
		nom: "",
		prenom: "",
		pseudo: "",
		email: "",
		password: "",
		telephone: "",
		informations: "",
		adresse_cp: "",
		adresse_ville: "",
		adresse_rue: "",
		adresse_numero_rue: "",
	});

	const handleGetInformations = async () => {
		console.log("CreateAccount >>> demande d'informations depuis le clientID : ");
		const response = await ax.get("/users/" + authData.clientID);
		setAccountData(response.data);
	};

	useEffect(() => {
		handleGetInformations();
		console.log("accountData:", accountData);
	}, []);

	const handleChange = (e) => {
		// Mettez à jour le state local à chaque modification du champ du formulaire
		setAccountData({
			...accountData,
			[e.target.name]: e.target.value,
		});
		console.log("authData:", accountData);
	};

	const handleSubmit = async (e) => {
		e.preventDefault();
		try {
			const response = await ax.put("/users/" + authData.clientID, { ...accountData });
			console.log("La mise a jour effectuée !");
			setMessage(true);
		} catch (error) {
			setError(true);
			console.error("Erreur lors de la soumission du formulaire", error);
		}
	};

	return (
		<div className="form-container">
			<h2>INFORMATIONS PERSONNELLES</h2>
			<p>Mon email : {authData.email}</p>
			<form className="update-compte" action="/users" method="put" encType="multipart/form-data" onSubmit={handleSubmit}>
				<div className="input">
					<label htmlFor="nom">Nom :</label>
					<input type="text" placeholder="Votre nom" id="nom" name="nom" value={accountData.nom} onChange={handleChange} autoComplete="off" required />
				</div>

				<div className="input">
					<label htmlFor="prenom">Prénom :</label>
					<input type="text" placeholder="Votre prénom" id="prenom" name="prenom" value={accountData.prenom} onChange={handleChange} autoComplete="off" required />
				</div>

				<div className="input">
					<label htmlFor="pseudo">Pseudo :</label>
					<input type="text" placeholder="Votre pseudo" id="pseudo" name="pseudo" value={accountData.pseudo} onChange={handleChange} autoComplete="off" required />
				</div>

				<div className="input">
					<label htmlFor="email">Email :</label>
					<input type="email" placeholder="Votre email" id="email" name="email" value={accountData.email} onChange={handleChange} autoComplete="off" required />
				</div>

				<div className="input">
					<label htmlFor="password">Mot de passe :</label>
					<input type="password" placeholder="Votre nouveau mot de passe" id="password" name="password" value={accountData.password} onChange={handleChange} autoComplete="off" required />
				</div>

				<div className="input">
					<label htmlFor="telephone">Téléphone :</label>
					<input type="text" placeholder="Votre téléphone" id="telephone" name="telephone" value={accountData.telephone} onChange={handleChange} autoComplete="off" required />
				</div>

				<div className="input">
					<label htmlFor="telephone">Informations </label>
					<input type="text" placeholder="Vos informations" id="informations" name="informations" value={accountData.informations} onChange={handleChange} autoComplete="off" />
				</div>

				<div className="input">
					<label htmlFor="adresse_cp">Code postal :</label>
					<input type="text" placeholder="Votre code postal" id="adresse_cp" name="adresse_cp" value={accountData.adresse_cp} onChange={handleChange} autoComplete="off" required />
				</div>
				<div className="input">
					<label htmlFor="adresse_ville">Ville :</label>
					<input type="text" placeholder="Votre ville" id="adresse_ville" name="adresse_ville" value={accountData.adresse_ville} onChange={handleChange} autoComplete="off" required />
				</div>

				<div className="input">
					<label htmlFor="adresse_rue">Rue :</label>
					<input type="text" placeholder="Votre rue" id="adresse_rue" name="adresse_rue" value={accountData.adresse_rue} onChange={handleChange} autoComplete="off" required />
				</div>

				<div className="input">
					<label htmlFor="adresse_numero_rue">N° rue :</label>
					<input type="text" placeholder="Votre n° de rue" id="adresse_numero_rue" name="adresse_numero_rue" value={accountData.adresse_numero_rue} onChange={handleChange} autoComplete="off" required />
				</div>
				{error && <p className="error">{error}</p>}
				<div className="input">
					<button type="submit" name="submit" className="primary">
						Mettre à jour mes informations
					</button>
				</div>
			</form>
			{error && <p className="error">Vérifiez vos informations, il doit y avoir une erreur, sinon essayez de vous reconnecter</p>}
			{message && <p className="valid">Vos informations ont été mises à jour !</p>}
		</div>
	);
};

export default UpdateAccount;
