import React, { useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import "../assets/css/buttons.css";

const PanierButton = () => {
	let navigate = useNavigate();

	const handlePanierClick = () => {};

	return (
		<Link to="/panier">
			<div className="panier-button" onClick={handlePanierClick}></div>
		</Link>
	);
};

export default PanierButton;
