import React, { useEffect } from "react";
import { Link } from "react-router-dom";

import "../assets/css/boutique.css";

const Boutique = () => {
	//const params = useParams();

	useEffect(() => {
		console.log("Boutique.jsx > montage x 2 ?");
	}, []);

	return (
		<section className="boutique">
			<div className="wide">
				<h2 className="categories-title">Nourriture</h2>
				<div className="categories-container nourriture">
					<Link to="https://passionkoi28.com/53-nourritures-de-printemps">
						<div>Nourriture printemps</div>
					</Link>
					<Link to="https://passionkoi28.com/54-nourritures-d-ete">
						<div>Nourriture été</div>
					</Link>
					<Link to="https://passionkoi28.com/55-nourritures-d-automne">
						<div>Nourriture automne</div>
					</Link>
					<Link to="https://passionkoi28.com/56-nourriture-d-hiver">
						<div>Nourriture hiver</div>
					</Link>
					<Link to="https://passionkoi28.com/57-nourriture-coulante">
						<div>Nourriture coulante</div>
					</Link>
					<Link to="https://passionkoi28.com/59-distributeurs-de-nourritures">
						<div>Distributeurs</div>
					</Link>
				</div>

				<h2 className="categories-title">Matériel</h2>
				<div className="categories-container materiel">
					<Link to="https://passionkoi28.com/13-pompes-bassins">
						<div>Pompes bassins</div>
					</Link>
					<Link to="https://passionkoi28.com/14-oxygene">
						<div>Oxygène</div>
					</Link>
					<Link to="https://passionkoi28.com/86-amenagement-de-bassin">
						<div>Aménagement bassin</div>
					</Link>
					<Link to="https://passionkoi28.com/28-filtres-uv-lampes">
						<div>Filtres UV & lampes</div>
					</Link>
					<Link to="https://passionkoi28.com/87-chauffage-de-bassin">
						<div>Chauffage de bassin</div>
					</Link>
					<Link to="https://passionkoi28.com/15-pieces-detachees">
						<div>Pièces détachées</div>
					</Link>
					<Link to="https://passionkoi28.com/88-etancheite-et-construction">
						<div>Etanchéité et construction</div>
					</Link>
					<Link to="https://passionkoi28.com/27-pvc-raccords-et-tuyaux">
						<div>PVC, Raccords et tuyaux</div>
					</Link>
					<Link to="https://passionkoi28.com/67-accessoires-divers-pour-bassin">
						<div>Accessoires divers pour bassin</div>
					</Link>
				</div>
			</div>
		</section>
	);
};

export default Boutique;
