// Login.jsx
import React, { useState, useEffect, useContext } from "react";
import { useNavigate, Link } from "react-router-dom";
import { JWTtoLocalStorage, AuthDataToLocalStorage } from "../services/jwt";

import ActivationBox from "../composants/ActivationBox";

import { AuthContext } from "../services/AuthContext";
import ax from "../services/axiosInterceptor";

import "../assets/css/App.css";
import "../assets/css/forms.css";

const Login = () => {
	const [email, setEmail] = useState("");
	const [password, setPassword] = useState("");
	const [showActivationBox, setShowActivationBox] = useState(false);
	const [message, setMessage] = useState(null);
	const navigate = useNavigate();
	const { authData, setAuthData } = useContext(AuthContext);

	useEffect(() => {}, []);

	const handleCloseActivationBox = () => {
		setShowActivationBox(false);
	};

	const handleSubmit = async (e) => {
		e.preventDefault();

		try {
			const response = await ax.post("/login", { email, password });
			const code = response.data.code;

			if (code === 2) {
				// Must activate !
				setMessage(response.data.message);
				setShowActivationBox(true);
			} else if (code === 1 || code === 3 || code === 4) {
				//Mot de passe incorrect !
				setMessage(response.data.message);
			} else if (code === 0) {
				// Do login here !

				JWTtoLocalStorage(response.data.jwt);
				AuthDataToLocalStorage(authData);

				setAuthData({ ...authData, email: email, clientID: response.data.clientID, panierID: 0 });
				navigate("/homepage");
			}
		} catch (error) {
			console.error("Erreur lors de la soumission du formulaire", error);
		}
	};

	return (
		<>
			<div className="form-container login">
				<h2>Connectez-vous</h2>
				<form className="login" onSubmit={handleSubmit}>
					<input type="text" name="email" value={email} onChange={(e) => setEmail(e.target.value)} placeholder="Entrez votre email" autoComplete="current-password" />
					<input type="password" name="password" value={password} onChange={(e) => setPassword(e.target.value)} placeholder="Entrez votre mot de passe" autoComplete="current-password" />
					<button className="primary" type="submit" name="submit">
						Se connecter
					</button>
					<div className="error-message">{message && <div>{message}</div>}</div>
				</form>
				{showActivationBox && <ActivationBox email={email} messageCode={0} close={handleCloseActivationBox} />}

				<Link to="/create-account">Pas encore inscrit ? créer un compte ici</Link>
			</div>
		</>
	);
};

export default Login;
