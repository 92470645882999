import React, { useState, useContext, useEffect } from "react";

import ax from "../services/axiosInterceptor";
import { AuthContext } from "../services/AuthContext";

import MessageBox from "../composants/MessageBox";
import "../assets/css/messages.css";

const CreateAccount = () => {
	const [showMessageBox, setShowMessageBox] = useState(false);
	const [error, setError] = useState(false);
	const { authData, setAuthData } = useContext(AuthContext);

	const [accountData, setAccountData] = useState({
		nom: "",
		prenom: "",
		pseudo: "",
		email: "",
		password: "",
		telephone: "",
		informations: "",
		adresse_cp: "",
		adresse_ville: "",
		adresse_rue: "",
		adresse_numero_rue: "",
	});

	const handleClose = () => {
		setShowMessageBox(false);
	};

	const handleChange = (e) => {
		const { name, value } = e.target;
		setAccountData((prevAccountData) => ({
			...prevAccountData,
			[name]: value,
		}));
	};

	const handleSubmit = async (e) => {
		e.preventDefault();
		console.log("Demande d'envoi...");
		console.table(accountData);
		try {
			const response = await ax.post("/users", { ...accountData });
			console.log("Formulaire envoyé!", response);
			setShowMessageBox(true);
		} catch (error) {
			console.error("Erreur lors de la soumission du formulaire", error);
		}
	};

	return (
		<div className="form-container">
			<h2>CREATION D'UN NOUVEAU COMPTE</h2>
			<form className="creation-compte" action="/users" method="post" encType="multipart/form-data" onSubmit={handleSubmit}>
				<div className="input">
					<label htmlFor="nom">Nom :</label>
					<input type="text" placeholder="Votre nom" id="nom" name="nom" value={accountData.nom} onChange={handleChange} autoComplete="off" required />
				</div>

				<div className="input">
					<label htmlFor="prenom">Prénom :</label>
					<input type="text" placeholder="Votre prénom" id="prenom" name="prenom" value={accountData.prenom} onChange={handleChange} autoComplete="off" required />
				</div>

				<div className="input">
					<label htmlFor="pseudo">Pseudo :</label>
					<input type="text" placeholder="Votre pseudo" id="pseudo" name="pseudo" value={accountData.pseudo} onChange={handleChange} autoComplete="off" required />
				</div>

				<div className="input">
					<label htmlFor="email">Email :</label>
					<input type="email" placeholder="Votre email" id="email" name="email" value={accountData.email} onChange={handleChange} autoComplete="off" required />
				</div>

				<div className="input">
					<label htmlFor="password">Mot de passe :</label>
					<input type="password" placeholder="Votre nouveau mot de passe" id="password" name="password" value={accountData.password} onChange={handleChange} autoComplete="off" required />
				</div>

				<div className="input">
					<label htmlFor="telephone">Téléphone :</label>
					<input type="text" placeholder="Votre téléphone" id="telephone" name="telephone" value={accountData.telephone} onChange={handleChange} autoComplete="off" required />
				</div>

				<div className="input">
					<label htmlFor="telephone">Informations </label>
					<input type="text" placeholder="Vos informations" id="informations" name="informations" value={accountData.informations} onChange={handleChange} autoComplete="off" />
				</div>

				<div className="input">
					<label htmlFor="adresse_cp">Code postal :</label>
					<input type="text" placeholder="Votre code postal" id="adresse_cp" name="adresse_cp" value={accountData.adresse_cp} onChange={handleChange} autoComplete="off" required />
				</div>
				<div className="input">
					<label htmlFor="adresse_ville">Ville :</label>
					<input type="text" placeholder="Votre ville" id="adresse_ville" name="adresse_ville" value={accountData.adresse_ville} onChange={handleChange} autoComplete="off" required />
				</div>

				<div className="input">
					<label htmlFor="adresse_rue">Rue :</label>
					<input type="text" placeholder="Votre rue" id="adresse_rue" name="adresse_rue" value={accountData.adresse_rue} onChange={handleChange} autoComplete="off" required />
				</div>

				<div className="input">
					<label htmlFor="adresse_numero_rue">N° rue :</label>
					<input type="text" placeholder="Votre n° de rue" id="adresse_numero_rue" name="adresse_numero_rue" value={accountData.adresse_numero_rue} onChange={handleChange} autoComplete="off" required />
				</div>
				{error && <p className="error">{error}</p>}
				<div className="input">
					<button type="submit" name="submit" className="primary">
						Créer le compte
					</button>
				</div>
			</form>
			{showMessageBox && <MessageBox message={"thanks"} close={handleClose} redirection={null} />}
		</div>
	);
};

export default CreateAccount;
