import React, { useContext, useEffect } from "react";
import { Link } from "react-router-dom";
import "../assets/css/megamenu.css";

const MegaMenu = ({ handleLeaveProps }) => {
	const handleLeave = () => {
		if (handleLeaveProps) {
			handleLeaveProps();
		}
		console.log("onMouseLeave megamenu detected.");
	};

	return (
		<div className="megamenu megamenu-animation" onMouseLeave={handleLeave}>
			<div className="megamenu-wrapper">
				<h4 className="megamenu-title">Poissons & Koïs</h4>
				<ul>
					<li>KOÏ DU JAPON</li>
					<li>KOÏ VOILÉE</li>
					<li>ESTURGEONS ET CARPES HERBIVORE ALBINOS</li>
					<li>SOINS ET MANIPULATIONS </li>
					<li>ALIMENTATION</li>
					<li>TRAITEMENTS DE L'EAU</li>
				</ul>
			</div>
			<div className="megamenu-wrapper">
				<h4 className="megamenu-title">Matériel bassins</h4>
				<ul>
					<Link to="/boutique/category/pompes">
						<li>POMPES BASSINS</li>
					</Link>
					<Link to="/boutique/category/2">
						<li>OXYGÈNE</li>
					</Link>
					<Link to="/boutique/category/3">
						<li>AMÉNAGEMENT DE BASSIN</li>
					</Link>

					<li>FILTRES UV & LAMPES</li>
					<li>CHAUFFAGE DE BASSIN</li>
				</ul>
			</div>
			<div className="megamenu-wrapper">
				<h4 className="megamenu-title">Matériel divers</h4>
				<ul>
					<li>PIÈCES DÉTACHÉES</li>
					<li>ETANCHÉITÉ ET CONSTRUCTION</li>
					<li>PVC, RACCORDS ET TUYAUX</li>
					<li>ACCESSOIRES DIVERS POUR BASSIN</li>
				</ul>
			</div>
			<div className="megamenu-wrapper">
				<h4 className="megamenu-title">Plantes aquatiques</h4>
				<ul>
					<li>NENUPHARES</li>
					<li>IRIS</li>
					<li>AUTRES</li>
				</ul>
			</div>
		</div>
	);
};

export default MegaMenu;
