import React, { useContext, useState } from "react";
import { BrowserRouter as Router, Route, Link, Routes } from "react-router-dom";
import { AuthContext } from "../services/AuthContext";
import "../assets/css/navigation.desktop.css";
import MegaMenu from "./MegaMenu.jsx";

const Navigation = () => {
	const { authData, setAuthData } = useContext(AuthContext);
	const [megamenuVisible, setMegamenuVisible] = useState(false);

	return (
		<>
			<nav>
				<ul>
					<li>
						<Link to="/">Accueil</Link>
					</li>
					<li>
						<Link to="/aquarium">Aquarium</Link>
					</li>
					<li>
						<Link to="/boutique" onClick={() => setMegamenuVisible(true)}>
							Boutique
						</Link>
					</li>
					{authData.email && (
						<li>
							<Link to="/panier">Panier</Link>
						</li>
					)}
					{authData.email && (
						<li>
							<Link to="/update-account">Mon compte</Link>
						</li>
					)}
					<li>
						<Link to="/contact">Contacter</Link>
					</li>
				</ul>
			</nav>
			{megamenuVisible && <MegaMenu handleLeaveProps={() => setMegamenuVisible(false)} />}
		</>
	);
};

export default Navigation;
