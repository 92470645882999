import "./assets/css/App.css";
import "./assets/css/header.css";
import React, { useState, useContext, useEffect } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

import { isMobile } from "react-device-detect";

import Homepage from "./pages/Homepage";
import Aquarium from "./pages/Aquarium";
import Boutique from "./pages/Boutique";
import Contact from "./pages/Contact";
import Panier from "./pages/Panier";
import UpdateAccount from "./pages/UpdateAccount";
import CreateAccount from "./pages/CreateAccount";
import Login from "./pages/Login";

import Navigation from "./composants/Navigation";
import NavigationMobile from "./composants/NavigationMobile";
import Footer from "./composants/Footer";
import LogoutButton from "./composants/LogoutButton";
import LoginButton from "./composants/LoginButton";
import PanierButton from "./composants/PanierButton";

import { AuthContext } from "./services/AuthContext";
import { isLogged } from "./services/jwt";

function App() {
	const { authData, setAuthData } = useContext(AuthContext);
	const [headerClass, setHeaderClass] = useState("main-header");
	const [logged, setLogged] = useState(false);

	useEffect(() => {
		setLogged(isLogged());
	}, []);

	return (
		<>
			<div className="main-container">
				<Router>
					{!isMobile && (
						<header className={headerClass}>
							<div className="wrapper">
								<LoginButton />
								<PanierButton />
								<LogoutButton />
							</div>
							<Navigation />
						</header>
					)}

					{isMobile && (
						<header className="mobile">
							<NavigationMobile />
						</header>
					)}
					<Routes>
						<Route path="/homepage" element={<Homepage />} />
						<Route path="/aquarium" element={<Aquarium />} />
						<Route path="/boutique/category/:id" element={<Boutique />} />
						<Route path="/boutique" element={<Boutique />} />
						<Route path="/login" element={<Login />} />
						{logged && <Route path="/update-account" element={<UpdateAccount />} />}
						{logged && <Route path="/panier" element={<Panier />} />}
						<Route path="/create-account" element={<CreateAccount />} />
						<Route path="/contact" element={<Contact />} />
						<Route path="/" element={<Homepage />} />
					</Routes>
				</Router>
				<Footer />
			</div>
		</>
	);
}

export default App;
