import React, { useContext, useState, useEffect } from "react";

import "../assets/css/navigation.desktop.css";
import "../assets/css/aquarium.css";
import "../assets/css/vignette.css";
import FichePoisson from "./FichePoisson";

const Vignette = ({ data, showTrash, showAddToCart }) => {
	const [pleinEcran, setPleinEcran] = useState(false);

	const handleClick = () => {
		// Mettez à jour l'état pour activer/désactiver le mode plein écran
		setPleinEcran(!pleinEcran);
	};

	const closeFichePoisson = () => {
		setPleinEcran(false);
	};

	return (
		<>
			{pleinEcran && <FichePoisson data={data} onClose={closeFichePoisson} />}
			<div className="vignette-product" id={data.id}>
				<div className="image-koi" style={{ backgroundImage: `url(${process.env.REACT_APP_API_URL}${"/uploads/fishes/" + data.image1})` }} onClick={handleClick}>
					<div className="addToCart">Ajouter</div>
				</div>
				{/* Icones Trash et Panier */}

				{/* Informations */}
				<div className="informations">
					<div className="reference">Nom de la carpe :&nbsp;{data.reference}</div>
					<div className="famille">Famille :&nbsp; {data.famille}</div>
					<div className="eleveur">Eleveur :&nbsp; {data.eleveur}</div>
					<div className="couleurs">Couleurs :&nbsp; {data.couleurs}</div>
					<div className="taille">Taille :&nbsp; {data.taille}cm</div>
					<div className="prix">Prix : &nbsp; {data.prix}€</div>
					<div className="type">Type : &nbsp; {data.type}</div>
					<div className="reserve">Reservée :&nbsp; {data.reserve_by} (négocier ici)</div>
				</div>
			</div>
		</>
	);
};

export default Vignette;
