import "../assets/css/App.css";
import "../assets/css/video.css";
import "../assets/css/homepage.css";

import React, { useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import { isMobile } from "react-device-detect";

import { AuthContext } from "../services/AuthContext";

function Homepage() {
	const { authData, setAuthData } = useContext(AuthContext);

	useEffect(() => {
		// Définir la position de défilement vertical à 0
		window.scrollTo(0, 0);
		console.log("authData", authData);
	}, []); // Utiliser un tableau vide comme dépendance pour s'assurer que cela ne se déclenche qu'une fois au montage

	return (
		<main>
			<section className={"la-boutique" + (isMobile ? " mobile" : " desktop")}>
				{authData.email && <div className="bienvenue">Bienvenue {authData.email}</div>}
				<div className="title right">
					<span className="big full">LA BOUTIQUE</span>
					<span className="little">L'essentiel </span>
					<span className="supplement"></span>
				</div>
				<div className="text-wrapper ">
					<Link className="block" to="/aquarium">
						<div className="circle aquarium"></div>
						<span>Aquarium</span>
					</Link>
					<Link className="block" to="https://passionkoi28.com/11-materiels">
						<div className="circle materiel"></div>
						<span>Matériel</span>
					</Link>
					<Link className="block" to="/contact">
						<div className="circle contact"></div>
						<span>Contactez-nous</span>
					</Link>
				</div>
			</section>
			<section className={"utilisation" + (isMobile ? " mobile" : " desktop")}>
				<div className="title">
					<span className="big full">LE SITE</span>
					<span className="little">Principe</span>
				</div>
				<div className="text-wrapper">
					<div className="image"></div>
					<span>
						🎏 <b>L'AQUARIUM</b> : Cherchez parmis nos plus belle carpes a l'aide d'un outil de selection
						<br />⚓ <b>LE MATERIEL </b>: Vous permet de trouver le matériel dont vous avez besoin et vous redirige directement sur PASSIONKOI28.COM
						<br />
						📫 <b>CONTACT</b> : Ecrivez-nous directement. Nous sommes à votre écoute pour tout renseignement.
					</span>
					<span>
						<br />
						🎌 Nos carpes sont <b>selectionnées au Japon</b> chez des éleveurs.
						<br />
						📥 Vous pouvez ensuite les <b>réserver</b>
						<br />
						🧺 <b>Acheter plus rapidement</b> vos produit de consommation (nourriture, soin etc) ainsi que le matériel de votre bassin (filtres à eau, pompes à oxygène etc)
					</span>
				</div>
			</section>

			<section className={"evenement" + (isMobile ? " mobile" : " desktop")}>
				<div className="title left">
					<span className="big full">EVENEMENT</span>
					<span className="little">A ne pas manquer !</span>
				</div>
				<div className="text-wrapper ">
					<div className="image"></div>
					<span className="text">Du 2 mars au 30 avril : porte ouvertes !!</span>
				</div>
			</section>

			<section className={"promotion" + (isMobile ? " mobile" : " desktop")}>
				<div className="title right">
					<span className="big full">PROMOTION</span>
					<span className="little">De la semaine</span>
				</div>
				<div className="text-wrapper ">
					<div className="image"></div>
					<span className="text">Cette semaine la filtration Polymove 2000 est en promotion. Avec une puissance de 2000w cette filtration gardera votre bassin parfaitement propre et l'eau saine pour vos carpes.</span>
				</div>
			</section>

			<section className={"carpe-de-la-semaine" + (isMobile ? " mobile" : " desktop")}>
				<div className="title left">
					<span className="big full">LA CARPE</span>
					<span className="little">De la semaine</span>
					<span className="supplement">contenu supplementaire</span>
				</div>
				<div className="text-wrapper ">
					<div className="image"></div>
					<span className="text">Cette semaine nous avons sélectionné cette Tancho de 45cm. En provance de l'éléveur MAGAMOTO. Au prix de 455€</span>
				</div>
			</section>

			<section className={"les-reseaux" + (isMobile ? " mobile" : " desktop")}>
				<div className="title center">
					<span className="big full">LES RESEAUX</span>
					<span className="little">Sociaux</span>
					{/*<span className="supplement">contenu supplementaire</span>*/}
				</div>
				<div className="text-wrapper ">
					<div className="wrapper">
						<Link className="block" to="https://www.facebook.com/profile.php?id=100057586863609">
							<div className="image"></div>
							<div className="text">FACEBOOK</div>
						</Link>
						<Link className="block" to="https://www.instagram.com/passionkoi28/">
							<div className="image"></div>
							<div className="text">INSTAGRAM</div>
						</Link>
					</div>
				</div>
			</section>

			{/*<section className="le-blog">
				<div className="wrapper">
					<span className="big-empty">LE BLOG</span>
					<span className="little-full">Bassins & découvertes...</span>
				</div>
				<div className="wrapper-content">Découvrez dans ce blog, comment batir un bassin, le décorer, l'entretenir. Apprenez à nourrir vos carpes avec les saisons et tout ce qu'il est indispensable ou les petits plus comme la végétation et la décoration.</div>
			</section>
			<section className="section-3 seo"></section>
			*/}
		</main>
	);
}

export default Homepage;
