import React, { useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import "../assets/css/buttons.css";

const LoginButton = () => {
	let navigate = useNavigate();
	const handleLoginClick = () => {
		navigate("/login");
	};

	return (
		<Link to="/login">
			<div className="login-button" onClick={handleLoginClick}></div>
		</Link>
	);
};

export default LoginButton;
