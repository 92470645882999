import "../assets/css/messages.css";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import ax from "../services/axiosInterceptor";

const ActivationBox = (props) => {
	const [activationCode, setActivationCode] = useState(""); // Initialiser l'état de l'activation code
	const [messageCode, setMessageCode] = useState(props.messageCode);
	const navigate = useNavigate();

	const handleSubmit = async (e) => {
		e.preventDefault();
		try {
			console.table("données envoyées :", props.email, activationCode);
			const response = await ax.post("/activation", { email: props.email, activation: activationCode });
			setMessageCode(1);
			console.log(response);
		} catch (error) {
			console.error("Erreur lors de la soumission du formulaire", error);
		}
	};

	const handleInputChange = (e) => {
		setActivationCode(e.target.value); // Mettre à jour l'état de l'activation code avec la valeur de l'input
	};

	const handleRedirectHome = (e) => {
		navigate("/homepage"); // Mettre à jour l'état de l'activation code avec la valeur de l'input
	};

	const handleClose = () => {
		props.close(); // Appeler la fonction close passée en prop depuis le parent
	};

	return (
		<div className="box backlayer">
			{messageCode === 0 && (
				<form className="box activation-code" onSubmit={handleSubmit}>
					<div className="cross" onClick={handleClose}>
						✖️
					</div>
					<p>
						Pour activer votre compte, <br></br>entrez votre code d'activation envoyé dans votre boîte mail
					</p>
					<input className="activation-code" maxLength="20" value={activationCode} onChange={handleInputChange} />
					<button type="submit">Envoyer le code</button>
				</form>
			)}
			{messageCode === 1 && (
				<form className="box activation-code">
					<div className="cross" onClick={handleClose}>
						✖️
					</div>
					<p> Merci d'avoir activé votre compte pk28 !</p>
				</form>
			)}
			{messageCode === 2 && (
				<form className="box activation-code">
					<div className="cross" onClick={handleClose}>
						✖️
					</div>
					<p>
						{" "}
						Votre message à été envoyé ! <br /> Merci de nous avoir contacté.
					</p>
					<button className="primary" onClick={handleRedirectHome}>
						OK
					</button>
				</form>
			)}
		</div>
	);
};

export default ActivationBox;
