import React, { useContext, useEffect } from "react";
import { Link } from "react-router-dom";
import { AuthContext, initAuthData } from "../services/AuthContext";
import "../assets/css/buttons.css";
import { removeAllFromLocalStorage } from "../services/jwt";

const LogoutButton = () => {
	const { authData, setAuthData } = useContext(AuthContext);

	const handleLogoutClick = () => {
		setAuthData(initAuthData);
		removeAllFromLocalStorage();
		console.log("authData", authData);
		console.log("Vous êtes déconnecté.");
	};

	return (
		<Link to="/aquarium">
			<div className="logout-button" onClick={handleLogoutClick}></div>
		</Link>
	);
};

export default LogoutButton;
